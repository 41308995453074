<template>
  <div class="hub-container text-center relative">
    <!-- WHITE HALO -->
    <div v-show="enigmasComplete" class="white-halo"></div>
    <!-- OVERLAY NAVIGATION -->
    <overlay-navigation class="overlay-navigation-container" :hide-return="true" />
    <!-- CIRCLE -->
    <div class="circle-container absolute opacity-0">
      <!-- MONOLITHE -->
      <canvas class="webgl-monolithe"></canvas>
      <div class="circle-plan">
        <div class="circle-gradient"></div>
        <div class="rotate-container">
          <div :class="'rotate-active-'+rotationIndex">
            <img src="../assets/hub/circle.png" alt="">
            <!-- KEYS -->
            <div :class="'cursor-pointer key key-'+enigma.id" v-for="enigma in enigmas" :key="enigma.id" @click="slideTo(enigma.id)">
              <img class="transition duration-1000 opacity-0" :class="{'opacity-100': !oResponses[enigma.questionName] && enigma.id != currentIndex}" src="../assets/hub/key-not-selected.png" alt="">
              <img class="transition duration-1000 opacity-0" :class="{'opacity-100': !oResponses[enigma.questionName] && enigma.id == currentIndex}" src="../assets/hub/key-selected.png" alt="">
              <img class="transition duration-1000 opacity-0" :class="{'opacity-100': oResponses[enigma.questionName] && enigma.id != currentIndex}" src="../assets/hub/key-founded-not-selected.png" alt="">
              <img class="transition duration-1000 opacity-0" :class="{'opacity-100': oResponses[enigma.questionName] && enigma.id == currentIndex}" src="../assets/hub/key-founded-selected.png" alt="">
              <span class="transition duration-1000 opacity-30" :class="{'opacity-100': enigma.id == currentIndex}">{{enigma.id}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTONS SWIPER -->
    <div class="swipers absolute bottom-24 sm:bottom-16 w-full text-center transition opacity-0 mb-8">
      <swiper
        class="hub-swiper"
        ref="hubSwiper"
        :options="swiperOptions"
        @slideChange="slideChange()"
      >
        <swiper-slide v-for="enigma in enigmas" :key="enigma.id">
          <button v-if="oResponses[enigma.questionName]" class="button-code mx-auto border-solid text-sm overflow-hidden text-sm border-white border-opacity-25" @click="goToFind(enigma.id, true)">
            <span>ENIGMA RESOLVED</span>
          </button>
          <button v-else class="button-code mx-auto border-solid text-sm cursor-pointer overflow-hidden text-sm border-quinary" @click="goToEnigma(enigma.id)">
            <span @click="goToEnigma(enigma.id)">SOLVE THE ENIGMA</span>
          </button>
        </swiper-slide>
      </swiper>

    </div>

  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import gsap from "gsap"
import { isProdEnv, clearThree } from "../helpers";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css';
import OverlayNavigation from '@/components/OverlayNavigation.vue'
export default {
  name: 'Hub',
  components: {
    Swiper,
    SwiperSlide,
    OverlayNavigation
  },
  data() {
    return {
      scene: null,
      enigmasComplete: false,
      currentIndex: 1,
      rotationIndex: 1,
      swiperOptions: {
        speed: 1000,
        slidesPerView: 1,
        loop: true,
        parallax:true,
        threshold: 20
      },
      enigmas: [
        {id:1, questionName: 'iQuestion_201' },
        {id:2, questionName: 'iQuestion_202' },
        {id:3, questionName: 'iQuestion_203' },
        {id:4, questionName: 'iQuestion_204' },
        {id:5, questionName: 'iQuestion_205' },
        {id:6, questionName: 'iQuestion_206' },
        {id:7, questionName: 'iQuestion_207' },
        {id:8, questionName: 'iQuestion_208' },
        {id:9, questionName: 'iQuestion_209' },
        {id:10, questionName: 'iQuestion_210' },
        {id:11, questionName: 'iQuestion_211' },
        {id:12, questionName: 'iQuestion_212' },
        {id:13, questionName: 'iQuestion_213' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      oResponses: "user/responses",
    }),
    swiper() {
      return this.$refs.hubSwiper.$swiper
    }
  },
  
  destroyed() {
    clearThree(this.scene)
    window.removeEventListener("keyup", this.pressKey, false);
  },
  
  methods: {
    pressKey(e) {
      if (e.keyCode === 37) {
        // arrow left
        if(this.currentIndex === 1) {
          this.slideTo(13)
        } else {
          this.slideTo(this.currentIndex - 1)
        }
      } else if (e.keyCode === 39) {
        // right left
        if(this.currentIndex === 13) {
          this.slideTo(1)
        } else {
          this.slideTo(this.currentIndex + 1)
        }
      } else if (e.keyCode === 13) {
        // enter key
        this.goToEnigma(this.currentIndex)
      }
    },
    slideTo(id, forceSlide) {
      if (!forceSlide && (id < this.currentIndex - 2 || id > this.currentIndex + 2) && id != 13 && id != 1) { // Prevent unwanted move
        return
      }
      if (forceSlide) {
        this.rotationIndex = id
        this.currentIndex = id
        this.swiper.slideTo(this.currentIndex)
      } else {
        if (id === 13 && this.currentIndex === 1) { // Gap exception: 1 to 13
          this.rotationIndex--
          this.currentIndex = id
          this.swiper.slideTo(this.currentIndex)
        } else if (id === 1 && this.currentIndex === 13) { // Gap exception: 13 to 1
          this.rotationIndex++
          this.currentIndex = id
          this.swiper.slideTo(this.currentIndex)
        } else if (this.currentIndex > id) { // Normal case
          this.rotationIndex--
          this.currentIndex--
          this.swiper.slideTo(this.currentIndex)
        } else if (this.currentIndex < id) { // Normal case
          this.rotationIndex++
          this.currentIndex++ 
          this.swiper.slideTo(this.currentIndex)
        }
      }
      
    },
    slideChange() {
      console.log('this.swiper.activeIndex', this.swiper.activeIndex)
      if (this.currentIndex === 1 && this.swiper.activeIndex === 0 && this.currentIndex !== 13) {
        this.rotationIndex--
        this.currentIndex = 13
        this.swiper.activeIndex = 13
      } else if (this.currentIndex === 13 && this.swiper.activeIndex >= 14 && this.currentIndex !== 1) {
        this.rotationIndex++
        this.currentIndex = 1
        this.swiper.activeIndex = 1
      } else if (this.currentIndex > this.swiper.activeIndex) { // Normal case
        this.rotationIndex--
        this.currentIndex--
      } else if (this.currentIndex < this.swiper.activeIndex) { // Normal case
        this.rotationIndex++
        this.currentIndex++
      }
    },
    goToShare() {
      this.$router
        .push({ name: 'share' })
        .catch(console.log)
    },
    goToInformation() {
      this.$router
        .push({ name: 'information' })
        .catch(console.log)
    },
    goToFind(id, resolved) {
      this.$router
        .push({ name: 'find', params: { idEnigma: id, resolved: resolved } })
        .catch(console.log)
    },
    goToEnigma(id, resolved) {
      console.log('id', id)
      this.$router
        .push({ name: 'enigma'+id })
        .catch(console.log)
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    }
  },
  mounted() {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
    // Init index
    if (this.$route.params.swiperIndex) {
      this.slideTo(parseInt(this.$route.params.swiperIndex), true)
    }
    // All enigma resolved ?
    let countResolvedEnigma = 0
    const enigmaNumber = 13
    Object.keys(this.oResponses)
      .filter(key => key.indexOf('iQuestion_2') != -1)
      .forEach(enigmaKey => {
        if (this.oResponses[enigmaKey]) {
          countResolvedEnigma++
        }
      })
    console.log('countResolvedEnigma: ', countResolvedEnigma)
    if (enigmaNumber === countResolvedEnigma || (this.$route.query.reveal && !isProdEnv())) {
      console.log('congratulations!')
      this.enigmasComplete = true
      // Start resolve animation
      let tlCongrats = gsap.timeline()
      tlCongrats.to('.circle-plan', {delay: 1,duration: 2, opacity: 0, y:100})
      let tlMonolithe = gsap.timeline()
        tlMonolithe
          .to('.webgl-monolithe', {delay: 1.7,duration: 2, scale:1.3, y:20})
          .to('.white-halo', {duration: 1, opacity:1, scale:25, onComplete: () => {
            document.querySelector('body').style.backgroundColor = 'white'
            this.$router
              .push({ name: 'congratulations' })
              .catch(console.log);
          }})
        
      let tlOverlay = gsap.timeline()
      tlOverlay
        .to('.overlay-navigation-container', {delay:1.5, duration: 0.5, opacity: 0})
        .to('.swiper-container', {duration: 1, opacity: 0, y:30})
      
    }

    // Circle apparition
    let tlContainer = gsap.timeline()
    tlContainer.to('.circle-container', {duration: 4, opacity: 1})

    // Circle rotation 
    document.querySelector('.rotate-container').classList.add('rotate-initialized')

    // Swiper apparition
    let tlSwiper = gsap.timeline()
    tlSwiper.to('.swipers', {delay: 1, duration: 1, opacity: 1})


    // MONOLITHE THREEJS
    // Canvas
    const canvas = document.querySelector('canvas.webgl-monolithe')
    // Scene
    this.scene = new THREE.Scene()
    // Textures
    const textureLoader = new THREE.TextureLoader()
    const colorTexture = textureLoader.load('/assets/monolithe/baseColor.jpg')
    // colorTexture.minFilter = THREE.LinearFilter
    // colorTexture.generateMipmaps = false
    const roughnessTexture = textureLoader.load('/assets/monolithe/roughness.jpg')
    // Obj Loader
    const objLoader = new OBJLoader()
    let monolithe = null

    objLoader.load(
      '/assets/monolithe/monolithe.obj',
      (obj) => {
        obj.traverse(function (child) {   // aka setTexture
          if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshStandardMaterial({ 
              map: colorTexture,
              roughnessMap: roughnessTexture
            });
          }
        });
        obj.position.y = -6
        monolithe = obj
        this.scene.add(monolithe)
      },
      (xhr) => {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )
      },
      (error) => {
        console.log('error', error)
      }
    )

    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.4)
    this.scene.add(ambientLight)
    const pointLight = new THREE.PointLight(0x444444, 1)
    pointLight.position.set(-5, -10, 5)
    this.scene.add(pointLight)
    const directionalLight = new THREE.DirectionalLight(0x444444, 0.7)
    directionalLight.position.set(2, 3, -12)
    this.scene.add(directionalLight)
    const directionalLight2 = new THREE.DirectionalLight(0x444444, 0.7)
    directionalLight2.position.set(8, 0, 0)
    this.scene.add(directionalLight2)
    const directionalLight3 = new THREE.DirectionalLight(0x444444, 0.7)
    directionalLight3.position.set(5, 0, 20)
    this.scene.add(directionalLight3)

    /**
     * Sizes
     */
    const sizes = {
        width: 300,
        height: 500
    }

    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = 300
        sizes.height = 500

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.set(- 8, 0, 8)
    this.scene.add(camera)

    // Controls
    const controls = new OrbitControls(camera, canvas)
    controls.target.set(0, 1, 0)
    controls.enableZoom = false
    controls.minPolarAngle = 1.7
    controls.maxPolarAngle = 1.7
    controls.enableDamping = true

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime

        if (monolithe) {
          monolithe.rotation.y += 0.005
        }
        // console.log('momom', monolithe)

        // Update controls
        controls.update()

        // Render
        renderer.render(this.scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

    window.addEventListener("keyup", this.pressKey, false);
  }
}
</script>

<style lang="scss" scoped>
  .hub-container {
    background: url('../assets/bg/hub-min.jpg');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/hub.jpg');
      background-size: cover;
    }

    .white-halo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 200px;
      opacity: 0;
      z-index: 999;
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 6%, rgba(255,255,255,0) 50%);
    }

    .rotate-container {
      transform: rotate(-15deg);
      transition: transform 3s cubic-bezier(0.68, -1.6, 0.32, 3.6);
      transition-delay: 0.3s;
      &.rotate-initialized {
        transform: rotate(0deg);
      }
    }
    .circle-container {
      top: 35%;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 380px) and (max-height: 700px) {
        top: 29%;
        .swipers {
          bottom: 7em !important;
        }
      }
      @media screen and (max-width: 340px) {
        transform: translateX(-50%) scale(0.6);
      }
      @media screen and (min-width: 1200px) and (min-height: 780px) {
        transform: translateX(-50%) scale(1.4);
      }
      .webgl-monolithe {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: -50%;
        z-index: 2;
      }
      .circle-plan {
        transform: rotateX(45deg);
      }
      @for $i from -26 through 26 {
        .rotate-active-#{$i} {
          transition: all 1s;
          transform: rotate(#{360/13*$i - 360/13}deg)
        }
      }
      .circle-gradient {
        position: absolute;
        width: 200%;
        height: 200%;
        left: -50%;
        z-index: 5;
        top: -100%;
        background: transparent linear-gradient(180deg, #0A0A0A 0%, #0A0A0AB2 75%, #0A0A0A00 100%) 0% 0% no-repeat padding-box;
      }
      .key {
        position: absolute;
        text-align: center;
        outline:none;
        -webkit-tap-highlight-color: transparent;
        top: 50%;
        left: 50%;
        height: 400px;
        width: 30px;
        transition: all 1s;
        img {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
        span {
          position: absolute;
          bottom: -30px;
          transform: translateX(-50%);
        }
      }
      @for $i from 1 through 13 {
        .key-#{$i} {
          transform: translate(-50%, -50%) rotate(#{-360/13*$i + 360/13}deg)
        }
      }
    }

    .hub-slide-item {
      width: 300px;
    }
    .swiper-container {
      height: 60px;
      @media screen and (min-height: 600px) {
        height: 70px;
      }
      @media screen and (min-height: 650px) {
        height: 70px;
      }
      @media screen and (min-height: 700px) {
        height: 80px;
      }
      @media screen and (min-height: 750px) {
        height: 120px;
      }
      @media screen and (min-height: 800px) {
        height: 150px;
      }
      @media screen and (min-height: 900px) {
        height: 210px;
      }
      @media screen and (min-height: 1000px) {
        height: 250px;
      }
      @media screen and (min-height: 1100px) {
        height: 320px;
      }
      @media screen and (min-height: 1200px) {
        height: 270px;
      }
      @media screen and (min-height: 1300px) {
        height: 430px;
      }
      @media screen and (min-height: 1400px) {
        height: 480px;
      }
      .swiper-slide {
        display: flex;
      }
      .button-code {
        border-width: 1px;
        width: 236px;
        outline: none;
        padding: 1em 0.1em;
        margin-top: auto;
      }
    }
  }
</style>